import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { getUrl } from 'API/getUrl'
import Swal from 'sweetalert2'
import { handleApi } from 'API/handleApiCall'
import { getDefaultOptions } from 'API/auth'
import { UserType } from 'types/UserAdditionMetaData'

interface LogBoxProps {
	log: any
	index: number
	quiz_id: string
	course_id: string
	students: Array<{ user: UserType }>
}
const LogBox: React.FC<LogBoxProps> = ({ log, index, quiz_id, course_id, students }) => {
	return (
		<>
			{/* <div className='col-lg-3 col-sm-index border'> */}
			<div>
				{' '}
				Student Name : {log.first_name ?? students[index]?.user?.first_name} {log.last_name ?? students[index]?.user?.last_name}
			</div>
			{/* <div>{img1}</div> */}
			<img
				className='border'
				// height={300}
				height={180}
				width={250}
				src={`${
					log?.photo?.photo_file ? getUrl(`static/${log?.photo?.photo_file}?token=${log?.photo?.photo_token}`) : '/img/blank_person.jpg'
				}`}
				// src={`${
				// 	elem?.photo.photo_file ? getUrl(`static/${elem?.photo.photo_file}?token=${elem.photo.photo_token}`) : ''
				// }`}
				alt=''
			/>
			<div
				onClick={async (e: any) => {
					e.preventDefault()
					Swal.fire({
						title: 'Are you Sure you want to Report this Student?',
						confirmButtonText: 'Ok',
						showCancelButton: true,
					}).then(async (result: any) => {
						if (result.value) {
							const report = await handleApi<any>(
								axios.post(
									getUrl('quiz/proctor/report_student'),
									{
										student_id: log.student.uid,
										quiz_id: quiz_id,
										report_reason: 'Suspicious Activity',
										report_action: true,
										action_time: log.action_time,
										course_id: course_id,
										end_exam: false,
									},
									getDefaultOptions()
								)
							)
							if (report) {
								alert('Student Reported')
							}
						} else {
						}
					})
				}}
				className='btn btn-primary'
			>
				Report Cheating
			</div>
			<div
				onClick={async (e: any) => {
					e.preventDefault()
					Swal.fire({
						title: 'Are you Sure you want to Report this Student! By this action student will not be able to give exam',
						confirmButtonText: 'Ok',
						showCancelButton: true,
					}).then(async (result: any) => {
						if (result.value) {
							const report = await handleApi<any>(
								axios.post(
									getUrl('quiz/proctor/report_student'),
									{
										student_id: log.student.uid,
										quiz_id: quiz_id,
										report_reason: 'Exam Ended because of suspicious activity',
										report_action: true,
										action_time: log.action_time,
										course_id: course_id,
										end_exam: true,
									},
									getDefaultOptions()
								)
							)
							if (report) {
								alert('Student Reported')
							}
						}
					})
				}}
				className='btn btn-primary'
			>
				End Exam
			</div>
			<div>
				{' '}
				Action Done :{' '}
				{log.exam_terminated ? 'Exam Terminated' : log.exam_ended ? 'Exam Finished' : log.action_done ?? 'Student Not Connected'}
			</div>
			{/* </div>{' '} */}
		</>
	)
}

export default LogBox
