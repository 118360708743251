import { Link, PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import { courseUsers } from 'API/course'
import 'styles/index.scss'
import React, { useRef, useState } from 'react'
import { getUrl } from 'API/getUrl'

import { get_document_teacher, get_exam_logs, get_student_latest_log } from 'API/quiz'
import Form from 'react-bootstrap/Form'
import { PromiseType } from 'utility-types'
// import Swal from 'sweetalert2'
import { UserType } from 'types/UserAdditionMetaData'
import { getQuiz } from 'API/getQuiz'
import { useAppDispatch } from 'redux/hooks'
import { QuizStateActions } from 'redux/slices/QuizState'
import { useAppSelector } from 'redux/hooks'
import BootstrapSwitchButton from 'components/switch/switch'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { getDefaultOptions } from 'API/auth'
import { start_exam } from 'API/quiz'
import Swal from 'sweetalert2'
import ChatBox from 'components/chat'
import LogBox from 'components/proctoring/logbox'

const LiveLogs: React.FC<PageProps> = ({ params: { id, quiz_id } }) => {
	const { ShowDetails, viewAs } = useGetCourseDetails(id)

	const [LiveLogs, setLiveLogs] = useState<any>([])
	const [documents, setDocuments] = useState<any>([])
	const [users, setUsers] = useState<{ teachers: Array<{ user: UserType }>; students: Array<{ user: UserType }> }>()
	const [query, setquery] = useState('')
	const [query1, setquery1] = useState('')
	const [show, setShow] = useState(false)
	const [comments, setcomments] = useState('Some error with Docs Please Re-Submit')
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	const [examStarted, setExamStarted] = useState(false)
	const dispatch = useAppDispatch()
	const questionData = useAppSelector((state) => state.QuizReducer.questionData)

	// const [asset, setAsset] = React.useState<PromiseType<ReturnType<typeof getQuiz>>>()
	React.useEffect(() => {
		const run = async () => {
			const data = await getQuiz({ quiz_id, id, viewAs })

			if (data) {
				dispatch(
					QuizStateActions.setState({
						questionData: {
							...data,
						},
						questions: [],
					})
				)
			}
		}

		run()
	}, [id, quiz_id, viewAs])

	// course teachers and students
	React.useEffect(() => {
		const run = async () => {
			if (id) {
				const users = await courseUsers(id)
				if (users) {
					console.log(users)
					setUsers(users)
				}
			}
		}
		run()
	}, [id])

	React.useEffect(() => {
		const run = async () => {
			if (viewAs === 'Teacher' && users) {
				getLiveLogs()
				getDocument()
				latestlog0()
				latestlog1()
				latestlog2()
				latestlog3()
				latestlog4()
				latestlog5()
				latestlog6()
				latestlog7()
				latestlog8()
				latestlog9()
				latestlog10()
				latestlog11()
				latestlog12()
				latestlog13()
				latestlog14()
				latestlog15()
				latestlog16()
				latestlog17()
				latestlog18()
				latestlog19()
			} else {
				return
			}
		}
		run()
	}, [viewAs, users])

	/// get latest log of one student add student_id that is uid dynamically to get the latest log of that student
	// React.useEffect(() => {
	// const [img1, setimg1] = useState('')
	const [img0, setimg0] = useState<any>([])
	const [img1, setimg1] = useState<any>([])
	const [img2, setimg2] = useState<any>([])
	const [img3, setimg3] = useState<any>([])
	const [img4, setimg4] = useState<any>([])
	const [img5, setimg5] = useState<any>([])
	const [img6, setimg6] = useState<any>([])
	const [img7, setimg7] = useState<any>([])
	const [img8, setimg8] = useState<any>([])
	const [img9, setimg9] = useState<any>([])
	const [img10, setimg10] = useState<any>([])
	const [img11, setimg11] = useState<any>([])
	const [img12, setimg12] = useState<any>([])
	const [img13, setimg13] = useState<any>([])
	const [img14, setimg14] = useState<any>([])
	const [img15, setimg15] = useState<any>([])
	const [img16, setimg16] = useState<any>([])
	const [img17, setimg17] = useState<any>([])
	const [img18, setimg18] = useState<any>([])
	const [img19, setimg19] = useState<any>([])

	const latestlog0 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[0]?.user.uid })
			if (log) {
				console.log('hi')
				console.log(log)
				setimg0(log)
			}
		} else {
			return
		}
		setTimeout(latestlog0, 5000)
	}

	const latestlog1 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[1]?.user.uid })
			if (log) {
				console.log('hi')
				console.log(log)
				setimg1(log)
			}
		} else {
			return
		}
		setTimeout(latestlog1, 5000)
	}
	const latestlog2 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[2]?.user.uid })
			if (log) {
				console.log('hi')
				console.log(log)
				setimg2(log)
			}
		} else {
			return
		}
		setTimeout(latestlog2, 5000)
	}

	const latestlog3 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[3]?.user.uid })
			if (log) {
				console.log('hi')
				console.log(log)
				setimg3(log)
			}
		} else {
			return
		}
		setTimeout(latestlog3, 5000)
	}

	const latestlog4 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[4]?.user.uid })
			if (log) {
				console.log('hi')
				console.log(log)
				setimg4(log)
			}
		} else {
			return
		}
		setTimeout(latestlog4, 5000)
	}

	const latestlog5 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[5]?.user.uid })
			if (log) {
				setimg5(log)
			}
		} else {
			return
		}
		setTimeout(latestlog5, 5000)
	}

	const latestlog6 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[6]?.user.uid })
			if (log) {
				setimg6(log)
			}
		} else {
			return
		}
		setTimeout(latestlog6, 5000)
	}

	const latestlog7 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[7]?.user.uid })
			if (log) {
				setimg7(log)
			}
		} else {
			return
		}
		setTimeout(latestlog7, 5000)
	}

	const latestlog8 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[8]?.user.uid })
			if (log) {
				setimg8(log)
			}
		} else {
			return
		}
		setTimeout(latestlog8, 5000)
	}

	const latestlog9 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[9]?.user.uid })
			if (log) {
				setimg9(log)
			}
		} else {
			return
		}
		setTimeout(latestlog9, 5000)
	}

	const latestlog10 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[10]?.user.uid })
			if (log) {
				setimg10(log)
			}
		} else {
			return
		}
		setTimeout(latestlog10, 5000)
	}

	const latestlog11 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[11]?.user.uid })
			if (log) {
				setimg11(log)
			}
		} else {
			return
		}
		setTimeout(latestlog11, 5000)
	}

	const latestlog12 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[12]?.user.uid })
			if (log) {
				setimg12(log)
			}
		} else {
			return
		}
		setTimeout(latestlog12, 5000)
	}

	const latestlog13 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[13]?.user.uid })
			if (log) {
				setimg13(log)
			}
		} else {
			return
		}
		setTimeout(latestlog13, 5000)
	}

	const latestlog14 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[14]?.user.uid })
			if (log) {
				setimg14(log)
			}
		} else {
			return
		}
		setTimeout(latestlog14, 5000)
	}

	const latestlog15 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[15]?.user.uid })
			if (log) {
				setimg15(log)
			}
		} else {
			return
		}
		setTimeout(latestlog15, 5000)
	}

	const latestlog16 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[16]?.user.uid })
			if (log) {
				setimg16(log)
			}
		} else {
			return
		}
		setTimeout(latestlog16, 5000)
	}

	const latestlog17 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[17]?.user.uid })
			if (log) {
				setimg17(log)
			}
		} else {
			return
		}
		setTimeout(latestlog17, 5000)
	}

	const latestlog18 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[18]?.user.uid })
			if (log) {
				setimg18(log)
			}
		} else {
			return
		}

		setTimeout(latestlog18, 5000)
	}

	const latestlog19 = async () => {
		if (viewAs === 'Teacher' && users) {
			const log = await get_student_latest_log({ quiz_id: quiz_id, student_id: users?.students[19]?.user.uid })
			if (log) {
				setimg19(log)
			}
		} else {
			return
		}
		setTimeout(latestlog19, 5000)
	}

	const getLiveLogs = async () => {
		console.log('LOG')
		console.log({ quiz_id })
		const data = await get_exam_logs({ quiz_id })
		if (data) setLiveLogs(data)

		setTimeout(getLiveLogs, 5000)
	}

	const getDocument = async () => {
		console.log('Get DOc')
		console.log({ quiz_id })
		console.log({ id })

		const document = await get_document_teacher({ quiz_id: quiz_id, course_id: id, status: 'Pending' })
		if (document) {
			setDocuments(document)
		} else {
			setDocuments([])
		}

		setTimeout(getDocument, 30000)
	}
	const [toggle, settoggle] = useState(true)
	const [toggle1, settoggle1] = useState(true)

	const reportStudent = async ({ student_id, action_time }: { student_id: string; action_time: Date }) => {
		const report = await handleApi<any>(
			axios.post(
				getUrl('proctor/report_student'),
				{
					student_id: student_id,
					quiz_id: quiz_id,
					report_reason: 'Multiple tabs open',
					report_action: true,
					action_time: action_time,
					course_id: id,
				},
				getDefaultOptions()
			)
		)
		if (report) {
			alert('Student Reported')
		}
	}

	return (
		<div>
			<BootstrapSwitchButton
				width={200}
				checked={toggle}
				onlabel='Dashboard'
				offlabel='User'
				offstyle='primary'
				onChange={(checked: boolean) => {
					settoggle(checked)
				}}
			/>
			{toggle && (
				<div>
					<div className='row'>
						<div className='col-6 mb-2'>
							{/* <input type='text' placeholder='search' onChange={(e) => setquery(e.target.value)} /> */}
							<span>
								<h1>Examination Dashboard</h1>
							</span>
							<p>Filter by Name :-</p>
							<Form.Control
								type='search'
								onChange={(e) => setquery(e.target.value)}
								placeholder='Search'
								className='me-2'
								aria-label='Search'
							/>
							<p>Test Trigger Button :-</p>
							{!questionData.can_start_exam && (
								<div
									onClick={async () => {
										alert('Are you Sure you want to start Exam? This Action Can not be undone.')
										const response = await start_exam({ quiz_id: quiz_id, can_start_exam: true, course_id: id })
										if (response) {
											if (response.can_start_exam) {
												alert('Exam Started')
												setExamStarted(response.can_start_exam)
												dispatch(
													QuizStateActions.setState({
														questionData: {
															...response,
														},
														questions: [],
													})
												)
											}
										} else {
											alert('Some Error Occured')
										}
									}}
									className='btn btn-primary'
								>
									Allow All students To Start Exam
								</div>
							)}
							{questionData.can_start_exam && <div>Exam Started</div>}
						</div>
						<div className='col-6'>
							<h2>Connect to Chat</h2>
							<ChatBox quiz_id={`${quiz_id}`} />
						</div>
					</div>
					<BootstrapSwitchButton
						width={200}
						checked={toggle1}
						onlabel='LIVE LOGS'
						offlabel='LIVE PHOTOS'
						offstyle='primary'
						onChange={(checked: boolean) => {
							settoggle1(checked)
						}}
					/>
					{toggle1 && (
						<div className='col-12'>
							<span>
								<h4>LIVE LOGS</h4>
							</span>

							<table className='table'>
								<thead>
									<tr>
										<th scope='col'>Index</th>
										<th scope='col'>Name</th>
										<th scope='col'>Username</th>
										<th scope='col'>Action Done</th>
										<th scope='col'>Action Time</th>
										{/* <th scope='col'>Student Photo</th> */}
									</tr>
								</thead>
								{LiveLogs?.filter((ele: any) => {
									const name = ele.student.first_name + ' ' + ele.student.last_name
									return name.includes(query)
								})?.map((elem: any, index: number) => {
									return (
										<>
											<tr>
												<th scope='row'>{index + 1}</th>
												<td>
													{elem.student.first_name} {elem.student.last_name}
												</td>
												<td>{elem.student.username}</td>
												{elem.action_done != 'EXAM STARTED' &&
													elem.action_done != 'EXAM ENDED' &&
													elem.action_done !== '5 SECOND TIMER' && <td style={{ color: 'red' }}>{elem.action_done}</td>}
												{(elem.action_done == 'EXAM STARTED' ||
													elem.action_done == 'EXAM ENDED' ||
													elem.action_done === '5 SECOND TIMER') && <td style={{ color: 'blue' }}>{elem.action_done}</td>}
												{/* <td style={{ background: 'red' }}>{elem.action_done}</td> */}
												<td>{new Date(elem.action_time).toLocaleString()}</td>
												{/* <td>
														{elem.photo && (
															<img
																height={300}
																src={`${
																	elem?.photo.photo_file
																		? getUrl(`static/${elem?.photo.photo_file}?token=${elem.photo.photo_token}`)
																		: ''
																}`}
																alt=''
															/>
														)}
													</td> */}
												<td>
													<div
														onClick={async (e: any) => {
															e.preventDefault()
															Swal.fire({
																title: 'Are you Sure you want to Report this Student?',
																confirmButtonText: 'Ok',
																showCancelButton: true,
															}).then(async (result: any) => {
																if (result.value) {
																	const report = await handleApi<any>(
																		axios.post(
																			getUrl('quiz/proctor/report_student'),
																			{
																				student_id: elem.student.uid,
																				quiz_id: quiz_id,
																				report_reason: 'Suspicious Activity',
																				report_action: true,
																				action_time: elem.action_time,
																				course_id: id,
																				end_exam: false,
																			},
																			getDefaultOptions()
																		)
																	)
																	if (report) {
																		alert('Student Reported')
																	}
																} else {
																}
															})
														}}
														className='btn btn-primary'
													>
														Report Cheating
													</div>
												</td>
												<td>
													<div
														onClick={async (e: any) => {
															e.preventDefault()
															Swal.fire({
																title: 'Are you Sure you want to Report this Student! By this action student will not be able to give exam',
																confirmButtonText: 'Ok',
																showCancelButton: true,
															}).then(async (result: any) => {
																if (result.value) {
																	const report = await handleApi<any>(
																		axios.post(
																			getUrl('quiz/proctor/report_student'),
																			{
																				student_id: elem.student.uid,
																				quiz_id: quiz_id,
																				report_reason: 'Exam Ended because of suspicious activity',
																				report_action: true,
																				action_time: elem.action_time,
																				course_id: id,
																				end_exam: true,
																			},
																			getDefaultOptions()
																		)
																	)
																	if (report) {
																		alert('Student Reported')
																	}
																}
															})
														}}
														className='btn btn-primary'
													>
														End Exam
													</div>
												</td>
											</tr>
										</>
									)
								})}
							</table>
						</div>
					)}
					{!toggle1 && users && (
						<div className='col-12'>
							<span>
								<h4>LIVE PHOTOS</h4>
							</span>
							<div className='row'>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img0} quiz_id={quiz_id} course_id={id} students={users?.students} index={0} />
								</div>

								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img1} quiz_id={quiz_id} course_id={id} students={users?.students} index={1} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img2} quiz_id={quiz_id} course_id={id} students={users?.students} index={2} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img3} quiz_id={quiz_id} course_id={id} students={users?.students} index={3} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img4} quiz_id={quiz_id} course_id={id} students={users?.students} index={4} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img5} quiz_id={quiz_id} course_id={id} students={users?.students} index={5} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img6} quiz_id={quiz_id} course_id={id} students={users?.students} index={6} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img7} quiz_id={quiz_id} course_id={id} students={users?.students} index={7} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img8} quiz_id={quiz_id} course_id={id} students={users?.students} index={8} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img9} quiz_id={quiz_id} course_id={id} students={users?.students} index={9} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img10} quiz_id={quiz_id} course_id={id} students={users?.students} index={10} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img11} quiz_id={quiz_id} course_id={id} students={users?.students} index={11} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img12} quiz_id={quiz_id} course_id={id} students={users?.students} index={12} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img13} quiz_id={quiz_id} course_id={id} students={users?.students} index={13} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img14} quiz_id={quiz_id} course_id={id} students={users?.students} index={14} />
								</div>

								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img15} quiz_id={quiz_id} course_id={id} students={users?.students} index={15} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img16} quiz_id={quiz_id} course_id={id} students={users?.students} index={16} />
								</div>

								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img17} quiz_id={quiz_id} course_id={id} students={users?.students} index={17} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img18} quiz_id={quiz_id} course_id={id} students={users?.students} index={18} />
								</div>
								<div className='col-lg-3 col-sm-6 border'>
									<LogBox log={img19} quiz_id={quiz_id} course_id={id} students={users?.students} index={19} />
								</div>
								{/* {LiveLogs?.filter((ele: any) => {
									const name = ele.student.first_name + ' ' + ele.student.last_name
									return name.includes(query)
								})?.map((elem: any) => {
									return (
										<>
											{elem.photo && (
												<div className='col-lg-3 col-sm-6 border'>
													<img
														className='border'
														// height={300}
														src={`${
															elem?.photo.photo_file
																? getUrl(`static/${elem?.photo.photo_file}?token=${elem.photo.photo_token}`)
																: ''
														}`}
														alt=''
													/>
													<div className='text-primary'>
														{elem.student.first_name} {elem.student.last_name}
													</div>
												</div>
											)}
										</>
									)
								})} */}
							</div>
						</div>
					)}
				</div>
			)}
			{!toggle && (
				<div>
					<div className='col-6 mb-2'>
						{/* <input type='text' placeholder='search' onChange={(e) => setquery(e.target.value)} /> */}
						<span>
							<h1>User Dashboard</h1>
						</span>
					</div>
					{/* <input onChange={(e) => setsearchname(e.target.value)} /> */}
					<div className='col-6 mb-2'>
						{/* <input type='text' placeholder='search' onChange={(e) => setquery(e.target.value)} /> */}
						<p>Filter by Name :-</p>
						<Form.Control
							type='search'
							onChange={(e) => setquery1(e.target.value)}
							placeholder='Search'
							className='me-2'
							aria-label='Search'
						/>
					</div>
					<div
						onClick={async (e: any) => {
							e.preventDefault()
							const document = await get_document_teacher({ quiz_id: quiz_id, course_id: id, status: 'Pending' })
							if (document) {
								setDocuments(document)
							} else {
								setDocuments([])
							}
						}}
						className='btn btn-primary'
					>
						Get Documents
					</div>
					<div className='col-12'>
						<span>
							<h4>User Details</h4>
						</span>
						<table className='table'>
							<thead>
								<tr>
									<th scope='col'>Index</th>
									<th scope='col'>Name</th>

									<th scope='col'>Document</th>
									<th scope='col'>Document</th>
									<th scope='col'>Student Photo</th>
									<th scope='col'>Approve/Reject</th>
								</tr>
							</thead>
							{documents
								?.filter((ele: any) => {
									const name = ele.user.first_name + ' ' + ele.user.last_name
									return name.includes(query1)
								})
								?.map((elem: any, index: number) => {
									return (
										<>
											<tr>
												<th scope='row'>{index + 1}</th>
												<td>
													{elem.user.first_name} {elem.user.last_name}
												</td>

												<td>
													{elem.document1.document1_file !== '' && (
														<iframe
															title='Document1'
															src={`${
																elem.document1.document1_file
																	? getUrl(
																			`static/${elem.document1.document1_file}?token=${elem.document1.document1_token}`
																	  )
																	: ''
															}#toolbar=0`}
															height={300}
															// style={{ width: '100%' }}
														></iframe>
													)}
												</td>
												<td>
													{elem.document2.document2_file !== '' && (
														<iframe
															title='Document2'
															src={`${
																elem.document2.document2_file
																	? getUrl(
																			`static/${elem.document2.document2_file}?token=${elem.document2.document2_token}`
																	  )
																	: ''
															}#toolbar=0`}
															height={300}
														></iframe>
													)}
												</td>
												<td>
													{elem.student_photo && (
														<img
															height={200}
															src={`${
																elem?.student_photo.student_photo_file
																	? getUrl(
																			`static/${elem?.student_photo.student_photo_file}?token=${elem.student_photo.student_photo_token}`
																	  )
																	: ''
															}`}
															alt=''
														/>
													)}
												</td>
												<td>
													<div className='row'>
														<div className='col-6'>
															<Button
																className='btn btn-primary'
																onClick={async (e: any) => {
																	e.preventDefault()
																	const result = await handleApi<any>(
																		axios.post(
																			getUrl('quiz/proctor/update_doc_status'),
																			{
																				student_id: elem.user.uid,
																				quiz_id: quiz_id,
																				status: 'Approved',
																				comment: 'All Docs Are Fine',
																				course_id: id,
																			},
																			getDefaultOptions()
																		)
																	)
																	if (result) {
																		alert('Approved')
																	} else {
																		alert('Something went wrong')
																	}

																	// navigate(`/admin/user/${e}`)
																}}
															>
																Approve
															</Button>{' '}
														</div>
														<div className='col-6'>
															<Button variant='primary' onClick={handleShow}>
																Reject
															</Button>{' '}
														</div>
													</div>

													<Modal show={show} onHide={handleClose}>
														<Modal.Header closeButton>
															<Modal.Title>Modal heading</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															<Form>
																<Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
																	<Form.Label>Comment</Form.Label>
																	<Form.Control
																		required
																		defaultValue={comments}
																		as='textarea'
																		rows={3}
																		onChange={(e) => setcomments(e.target.value)}
																	/>
																</Form.Group>
															</Form>
														</Modal.Body>
														<Modal.Footer>
															<Button variant='secondary' onClick={handleClose}>
																Close
															</Button>
															<Button
																variant='primary'
																onClick={async (e: any) => {
																	e.preventDefault()
																	const result = await handleApi<any>(
																		axios.post(
																			getUrl('quiz/proctor/update_doc_status'),
																			{
																				student_id: elem.user.uid,
																				quiz_id: quiz_id,
																				status: 'Rejected',
																				comment: comments,
																				course_id: id,
																			},
																			getDefaultOptions()
																		)
																	)
																	if (result) {
																		alert('Rejected')
																		setShow(false)
																	} else {
																		alert('Something went wrong')
																		setShow(false)
																	}
																}}
															>
																Reject
															</Button>
														</Modal.Footer>
													</Modal>
												</td>
											</tr>
										</>
									)
								})}
						</table>
					</div>
				</div>
			)}
		</div>
	)
}

export default LiveLogs
